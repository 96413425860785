import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import departmentServices from '@attendance-web-app/services/department.services';
import {
  default as employeeServices,
  default as getAllEmployeeServices
} from '@attendance-web-app/services/employee.services';
import { IDepartmentDropdownOptions } from '@attendance-web-app/types/department/type';
import { IDesignationFormData } from '@attendance-web-app/types/designation/type';

import Alert from '@attendance-web-app/components/Common/Alert';
import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import { IEmployeeList, IemployeeSupervisorDropdownOptions } from '@attendance-web-app/types/employee/type';
import { shiftArr } from '@attendance-web-app/utils';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import Select from 'react-select';
import role from '../../../__mocks__/role.json';

interface IInviteEmployeeTabFormProps {
  id?: number;
  employeeListState?: IEmployeeList;
  onHide: any;
}
interface inviteEmployeeFormFields {
  email: string;
  employeeIdNo: string;
  grade: string;
  mobile: number;
  department: IDepartmentDropdownOptions;
  supervisorId: IemployeeSupervisorDropdownOptions | null;
  role: string;
  designation?: IDesignationFormData | null | undefined;
  shift?: 'day' | 'evening';
  subDepartment?: IDesignationFormData;
}

const InviteEmployeeTabForm = ({ onHide }: IInviteEmployeeTabFormProps) => {
  const [deptOptions, setDeptOtions] = useState<IDepartmentDropdownOptions[]>([{ value: '', label: '', id: 0 }]);
  const [superVisorOptions, setsuperVisorOptions] = useState<any>([{ value: '', label: '' }]);
  const [desginationOptions, setdesginationOptions] = useState<any>([{ value: 0, label: '', grade: '' }]);
  const [subDepartmentOptions, setSubDepartmentOptions] = useState<any>([{ value: 0, label: '' }]);
  const [typeSuperVisor, setTypedSuperVisor] = useState('');
  const [isOptionsOpen, setOptionsOpen] = useState(false);

  const location = useLocation();
  const { designationName, subDepartmentId, subDepartmentName, departmentName, departmentId, designationId } =
    location?.state ?? {};

  const [designationValue, setDesignationValue] = useState<
    | undefined
    | null
    | {
        value: number;
        label: string;
      }
  >(
    designationId && designationName
      ? {
          value: designationId || '',
          label: designationName || ''
        }
      : undefined
  );
  const [subDepartmentValue, setSubDepartmentValue] = useState<
    | undefined
    | null
    | {
        value?: number;
        label?: string;
      }
  >(
    subDepartmentId && subDepartmentName
      ? {
          value: subDepartmentId || '',
          label: subDepartmentName || ''
        }
      : undefined
  );

  const params = useParams();
  const loggedInUserId = JSON.parse(localStorage.getItem('admin_user') as string).id;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    setValue,
    getValues
  } = useForm<inviteEmployeeFormFields>({
    defaultValues: {
      shift: 'day',
      subDepartment: subDepartmentValue || undefined,
      designation: designationValue,
      employeeIdNo: 'FUSE-'
    }
  });

  useEffect(() => {
    getdepartmentOptions();
    const subscription = watch((_, { name }) => {
      if (name === 'department') {
        getSubDepartmentOptions();
      }
      if (name === 'subDepartment') {
        getDesignationOptions();
        getSuperVisorOptions();
      }
    });

    return () => subscription.unsubscribe();
  }, [typeSuperVisor, watch]);

  const getdepartmentOptions = async () => {
    try {
      const getdepartmentListArgs = { name: '', limit: 50 };
      const allDepartment = await departmentServices.getdepartmentList(getdepartmentListArgs);
      const allDepartmentData = allDepartment.data.departments
        .filter((department: any) => department.isActive === true)
        .map((department: any) => ({
          value: department.departmentIdNo,
          label: department.name,
          id: Number(department.id)
        }));
      setDeptOtions(allDepartmentData);
    } catch (err) {
      console.log(err);
    }
  };

  const getSubDepartmentOptions = async () => {
    try {
      if (getValues('department.value')) {
        setSubDepartmentValue(null);
        setDesignationValue(null);

        setValue('subDepartment.value', undefined);
        const selectedSubDepartment = await axios.get('/sub-department', {
          params: { departmentId: getValues('department.id'), limit: 100, offset: 0 }
        });
        setSubDepartmentOptions(
          selectedSubDepartment.data?.items?.map((subDepartment: any) => ({
            value: subDepartment.id,
            label: subDepartment.name
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getDesignationOptions = async () => {
    try {
      if (getValues('department.value') || getValues('subDepartment.value')) {
        setDesignationValue(null);
        const selectedDesignation = await axios.get('/designation', {
          params: { subDepartment: getValues('subDepartment.label') }
        });
        setdesginationOptions(
          selectedDesignation.data.map((designation: any) => ({
            value: designation.id,
            label: designation.name,
            grade: designation?.grade
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSuperVisorOptions = async () => {
    try {
      const employeeListArgs = {
        name: typeSuperVisor,
        department: String(getValues('department.id')),
        limit: 1,
        offset: 0
      };
      const superVisor = await employeeServices.employeeList(employeeListArgs);
      const superVisorData = superVisor.data.employee.map(employee => {
        return {
          label: employee.name,
          email: employee.email,
          image: employee.profilePhoto,
          id: employee.id,
          employeeIdNo: employee.employeeIdNo
        };
      });
      setsuperVisorOptions(superVisorData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateEmployee: SubmitHandler<inviteEmployeeFormFields> = async data => {
    try {
      const createEmployeeBody = {
        email: data?.email?.trim(),
        mobile: Number(data.mobile),
        designation: Number(data.designation?.value),
        department: Number(data.department.id),
        subDepartment: data?.subDepartment?.value ? Number(data.subDepartment.value) : null,
        role: Number(data.role),
        employeeIdNo: data.employeeIdNo,
        status: false,
        supervisorId: Number(data.supervisorId?.id) || null,
        employeeId: loggedInUserId,
        grade: data.grade,
        shift: data.shift
      };

      await getAllEmployeeServices.createEmployee(createEmployeeBody);
      onHide();
      Alert('success', 'New employee has been invited successfully.');
    } catch (err: any) {
      err.response.data.message.map((message: any) => {
        if (message.includes('Email')) {
          setError('email', { message: message });
        }
        if (message.includes('Mobile')) {
          setError('mobile', { message: message });
        }
        if (message.includes('EmployeeIdNo')) {
          setError('employeeIdNo', { message: message });
        }
      });
    }
  };
  const handleEmployeeIdChange = (e: any) => {
    const inputValue = e.target.value;
    if (!inputValue.startsWith('FUSE-')) {
      setValue('employeeIdNo', 'FUSE-');
    } else {
      const numericPart = inputValue.replace('FUSE-', '');
      if (!isNaN(numericPart) || numericPart === '') {
        setValue('employeeIdNo', `FUSE-${numericPart}`);
      }
    }
  };
  useEffect(() => {
    if (deptOptions.length > 0 && params.departmentName) {
      setValue('department', {
        id: deptOptions.find(item => item.label === (params.departmentName || departmentName))?.id || '',
        value: deptOptions.find(item => item.label === (params.departmentName || departmentName))?.value || '',
        label: deptOptions.find(item => item.label === (params.departmentName || departmentName))?.label || ''
      });
    }
  }, [deptOptions]);

  return (
    <>
      <form action="" onSubmit={handleSubmit(handleCreateEmployee)} id="invite-form" className="invite-form" noValidate>
        <div className="row mt-5">
          <Form.Group className="form-group-empEmail form-group-id ">
            <Form.Control
              type="text"
              placeholder="Email"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@fusemachines\.com$/,
                  message: 'Employees must have fusemachines.com email.'
                }
              })}
              isInvalid={!!errors.email}
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className={`modal-label customlabel-empEmail  ${errors.email ? 'text-danger' : ''}`}
            >
              Email <span className="asteriskEmployee">*</span>
            </label>
            {errors.email && <ErrorLabel message={errors.email.message || 'Email is required'} />}
          </Form.Group>
        </div>
        <div className="body-regular-semibold mt-3 mb-2">Employee details</div>
        <div className="row  mt-4">
          <div className="form-floating col">
            <Form.Group className="form-group-empId">
              <Form.Control
                type="text"
                placeholder="Employee ID"
                {...register('employeeIdNo', {
                  required: true,
                  validate: {
                    isValidFormat: value => {
                      return /^FUSE-\d*$/.test(value) || 'Must be in the format FUSE-(NUMBER)';
                    }
                  },
                  onChange: handleEmployeeIdChange
                })}
                isInvalid={!!errors.employeeIdNo}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={`customlabel-empId ${errors.employeeIdNo ? 'text-danger' : ''}`}
              >
                Employee ID <span className="asteriskEmployee">*</span>
              </label>
              {errors.employeeIdNo && <ErrorLabel message={errors.employeeIdNo.message || 'Required'} />}
            </Form.Group>
          </div>
          <div className="form-floating col">
            <Form.Group className="form-group-empGrade">
              <Form.Control
                type="text"
                placeholder="Employee Grade"
                {...register('grade', {
                  required: true
                })}
                isInvalid={!!errors.grade}
                autoComplete="off"
                disabled
              />
              <label
                htmlFor="floatingInputCustom"
                className={`customlabel-empGrade ${errors.grade ? 'text-danger' : ''}`}
              >
                Employee Grade <span className="asteriskEmployee">*</span>
              </label>
              {errors.grade && <ErrorLabel message={errors.grade.message || 'Required'} />}
            </Form.Group>
          </div>
        </div>
        <div className="row flex-option">
          <div className="form-floating">
            <Controller
              control={control}
              render={({ field: { onChange, value, name } }) => {
                const isDisabled = params?.departmentName ? true : false;
                const selectedDropdownDepartment = deptOptions.find(
                  (department: IDepartmentDropdownOptions) => department.value === String(value)
                );
                const paramsDepartment = deptOptions.findIndex(
                  (department: IDepartmentDropdownOptions) =>
                    department.label === String(params.departmentName || departmentName)
                );

                const handleSelectChange = (selectedOption: IDepartmentDropdownOptions | null) => {
                  if (selectedOption) {
                    onChange(selectedOption);
                    setValue('department', {
                      id: selectedOption.id,
                      label: selectedOption?.label,
                      value: selectedOption?.value
                    });
                    setValue('supervisorId', null);
                  }
                };

                return (
                  <Select
                    value={
                      params?.departmentName || departmentId
                        ? deptOptions[paramsDepartment]
                        : selectedDropdownDepartment
                    }
                    name={name}
                    isDisabled={isDisabled}
                    options={deptOptions}
                    onChange={handleSelectChange}
                    className={`select-input ${errors.department ? 'has-error' : ''}`}
                    placeholder="-Select Department-"
                  />
                );
              }}
              name="department"
              rules={{
                required: true
              }}
            />
            <label
              className={`select-label invite-employee-select ${errors.department ? 'text-danger' : ''}`}
              htmlFor="selectdepartment"
            >
              <span className="select-text">
                Department <span className="asteriskEmployee">*</span>{' '}
              </span>
            </label>

            {errors.department && <ErrorLabel message={errors.department.message || 'Required'} />}
          </div>

          <div className="form-floating col-6 ">
            <Controller
              control={control}
              render={({ field: { onChange, name } }) => {
                const handleSelectChange = (selectedOption: any | null) => {
                  if (selectedOption) {
                    onChange(selectedOption);
                    setSubDepartmentValue(selectedOption);
                  }
                };

                return (
                  <Select
                    value={subDepartmentValue}
                    name={name}
                    isDisabled={!getValues('department.value')}
                    options={getValues('department.id') ? subDepartmentOptions : undefined}
                    onChange={handleSelectChange}
                    className={`select-input ${errors.department ? 'has-error' : ''}`}
                    placeholder="- Select -"
                    noOptionsMessage={() => 'No Sub Department'}
                  />
                );
              }}
              name="subDepartment"
              rules={{
                required: true
              }}
            />
            <label className={`select-label ${errors.designation ? 'text-danger' : ''}`} htmlFor="selectSubDepartment">
              <span className="select-text">
                {' '}
                Select Sub Department <span className="asteriskEmployee">*</span>
              </span>
            </label>
            {errors.designation && <ErrorLabel message={errors.designation.message || 'Required'} />}
          </div>
          <div className="form-floating col-6 ">
            <Controller
              control={control}
              render={({ field: { onChange, value, name } }) => {
                const selectedDesignation = desginationOptions.find((designation: any) => designation.value === value);
                const handleSelectChange = (selectedOption: any | null) => {
                  if (selectedOption) {
                    onChange(selectedOption);
                    setDesignationValue(selectedOption);
                    setValue('grade', selectedOption?.grade);
                  }
                };
                return (
                  <Select
                    value={designationValue}
                    name={name}
                    isDisabled={!getValues('subDepartment.value') || !getValues('department.value')}
                    options={getValues('department.id') ? desginationOptions : undefined}
                    onChange={handleSelectChange}
                    className={`select-input ${errors.department ? 'has-error' : ''}`}
                    placeholder="-Select Designation-"
                    noOptionsMessage={() => 'No Designation Found'}
                  />
                );
              }}
              name="designation"
              rules={{
                required: true
              }}
            />
            <label className={`select-label ${errors.designation ? 'text-danger' : ''}`} htmlFor="selectdepartment">
              <span className="select-text">
                {' '}
                Select Designation <span className="asteriskEmployee">*</span>
              </span>
            </label>
            {errors.designation && <ErrorLabel message={errors.designation.message || 'Required'} />}
          </div>
        </div>
        <div className="row mt-4">
          <div className="form-floating col-6 ">
            <Form.Group className="form-group-empMobile-no">
              <Form.Control
                type="text"
                maxLength={10}
                placeholder="Mobile Number"
                {...register('mobile', {
                  required: true,
                  validate: {
                    isNumber: value => !isNaN(value) || 'Mobile number must be a number',
                    isTenDigits: value => String(value).length === 10 || 'Phone number must be 10 digits long'
                  }
                })}
                isInvalid={!!errors.mobile}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={`customlabel-empMobile-no ${errors.mobile ? 'text-danger' : ''}`}
              >
                Mobile Number <span className="asteriskEmployee">*</span>
              </label>
              {errors.mobile && <ErrorLabel message={errors.mobile.message || 'Required'} />}
            </Form.Group>
          </div>
          <div className="form-floating col-6 role">
            <Controller
              control={control}
              render={({ field: { onChange, value, name } }) => {
                const selectedRole = role.role.find((c: any) => c.value === value);
                const handleSelectChange = (selectedOption: any | null) => {
                  if (selectedOption) {
                    onChange(selectedOption.value);
                  }
                };
                return (
                  <Select
                    value={selectedRole}
                    name={name}
                    options={role.role}
                    onChange={handleSelectChange}
                    className={`select-input ${errors.role ? 'has-error' : ''}`}
                    placeholder="-Select Role-"
                  />
                );
              }}
              name="role"
              rules={{
                required: true
              }}
            />
            <label
              className={`select-label invite-employee-select ${errors.role ? 'text-danger' : ''}`}
              id="designation"
              htmlFor="selectRole"
            >
              <span className="select-text">
                Select Role <span className="asteriskEmployee">*</span>
              </span>
            </label>
            {errors.role && <ErrorLabel message={errors.role.message || 'Required'} />}
          </div>
        </div>

        <div className="form-floating row flex-option">
          <div className="form-floating col-6">
            <Controller
              control={control}
              render={({ field: { onChange, value, name } }) => {
                const selectedShift = shiftArr.find((c: any) => c.value === value);
                const handleSelectChange = (selectedOption: any | null) => {
                  if (selectedOption) {
                    onChange(selectedOption.value);
                  }
                };

                return (
                  <Select
                    value={selectedShift ?? shiftArr[0]}
                    defaultValue={shiftArr[0]}
                    name={name}
                    options={shiftArr}
                    onChange={handleSelectChange}
                    className={`select-input ${errors.shift ? 'has-error' : ''}`}
                    placeholder="-Select Shift-"
                  />
                );
              }}
              name="shift"
            />
            <label
              className={`select-label invite-employee-select ${errors.shift ? 'text-danger' : ''}`}
              id="designation"
              htmlFor="selectShift"
            >
              <span className="select-text">
                Select Shift <span className="asteriskEmployee">*</span>
              </span>
            </label>
            {errors.shift && <ErrorLabel message={errors.shift?.message || 'Required'} />}
          </div>
          <div className="form-floating col-6 ">
            <Controller
              control={control}
              render={({ field: { onChange, name } }) => {
                const handleSelectChange = (selectedOption: any | null) => {
                  if (selectedOption === null) {
                    onChange(selectedOption);
                    setValue(name, selectedOption);
                    setTypedSuperVisor('');
                    setOptionsOpen(true);
                  }
                  if (selectedOption) {
                    onChange(selectedOption);
                    setValue(name, selectedOption);
                    setOptionsOpen(false);
                    setTypedSuperVisor(selectedOption.label);
                  }
                };

                const handleInputSupervisor = (valueinput: string) => {
                  setTypedSuperVisor(valueinput);
                };

                const Option = (props: any) => {
                  const { label, email, image, id, employeeIdNo } = props.data;
                  if (getValues('department.id')) {
                    return (
                      <div
                        className="d-flex pb-2 pt-2 mb-2 ms-3 border-bottom"
                        onClick={() => {
                          handleSelectChange({
                            label: email,
                            id: id,
                            image: image
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="flex-shrink-0 mt-2">
                          {image ? (
                            <img
                              src={image}
                              alt={label}
                              style={{
                                width: '50px',
                                height: '50px',
                                marginRight: '10px'
                              }}
                              className="round-image"
                            />
                          ) : (
                            <AvatarName name={label} />
                          )}
                        </div>
                        <div className="flex-shrink-1 ms-3 mt-1">
                          <div>{label}</div>
                          <div style={{ fontSize: '12px', color: '#999' }}>{email}</div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="d-flex flex-1 pb-3 pt-3 w-100 text-align-center justify-content-center">
                        <span>Please select a Department</span>
                      </div>
                    );
                  }
                };

                return (
                  <Select
                    className={'select-input'}
                    name={name}
                    options={superVisorOptions}
                    placeholder="-Select Supervisor-"
                    isSearchable
                    value={getValues(name)}
                    components={{ Option }}
                    onChange={handleSelectChange}
                    onInputChange={handleInputSupervisor}
                    menuIsOpen={isOptionsOpen}
                    inputValue={typeSuperVisor}
                    onFocus={() => {
                      setOptionsOpen(true);
                    }}
                    onBlur={() => {
                      setOptionsOpen(false);
                    }}
                    onKeyDown={() => {
                      setOptionsOpen(true);
                    }}
                    noOptionsMessage={() => 'No Supervisor Found'}
                    isClearable
                  />
                );
              }}
              name="supervisorId"
            />

            <label className={'select-label invite-employee-select'} htmlFor="selectdepartment">
              <span className="select-text">Select Supervisor</span>
            </label>
          </div>
        </div>
      </form>
    </>
  );
};

export default InviteEmployeeTabForm;
