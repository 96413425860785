import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import VendorPaymentModal from '@attendance-web-app/components/_pages/_VendorList/VendorMakePaymentModal';
import VendorTransactionFilter from '@attendance-web-app/components/_pages/_VendorSummary/VendorTransactionFilter';
import VendorSummary from '@attendance-web-app/components/_pages/_VendorSummary/vendorSummary';
import CustomSearchBar from '@attendance-web-app/components/_pages/_header/LogSearchBar';
import { formatAmountToStandardFormat } from '@attendance-web-app/utils';
import { convertDateFormat } from '@attendance-web-app/utils/foramttedDate';
import { format, subDays } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useVenderView } from './hooks/useVendorView';

const VendorView = () => {
  const params = useParams();
  const [modalShow, setModalShow] = useState(false);
  // const [show, setShow] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  // const showPayout = () => {
  //   setShow(false);
  // };

  const {
    vendorDetail,
    refetchVendorDetail,
    vendorPayoutDetail,
    refetchVendorPayoutDetail,
    onChangeSelectHandler,
    onInputChange,
    bankOptions,
    selectedBank,
    setChequeNo,
    chequeNo,
    onStartDateChangeHandler,
    onEndDateChangeHandler,
    startDate,
    endDate,
    onApplyHandler,
    transactionDetail,
    resetFilter,
    ErrorMessage,
    resetEndDate,
    resetStartDate,
    refetchVendorTransaction,
    selectedStartDate,
    selectedEndDate
  } = useVenderView(params.vendorId ?? '') as any;
  const refetchVendor = () => {
    const refetchParams = { id: params.vendorId } as any;
    refetchVendorDetail(refetchParams);
  };

  const openAdvanceFilter = () => {
    setShowFilter(true);
  };

  const hideAdvancedFilter = () => {
    setShowFilter(false);
  };
  const cancelAdvancedFilter = () => {
    setShowFilter(false);
    resetFilter();
    refetchVendorTransaction({ vendorId: params.vendorId });
  };
  const onHandleMakePayment = () => {
    setModalShow(true);
    setShowFilter(false);
  };
  return vendorDetail ? (
    <>
      <div className="vendorDetailHeader">
        <div className="vendorHeader">
          <div className="h4-semibold ">Vendor Detail</div>

          <div className="d-flex" style={{ gap: '5px' }}>
            <CustomSearchBar toggleAdvancedFilter={openAdvanceFilter} hasInputBox={false} />
            <button className="btn invite-button  body-large-regular" onClick={onHandleMakePayment}>
              <span className="body-regular invite-text"> Make Payment </span>
            </button>
            <VendorPaymentModal
              refetchPaymentDetails={refetchVendorTransaction}
              show={modalShow}
              onHide={() => setModalShow(false)}
              transactionDetail={transactionDetail}
              refetchVendorPayoutDetail={refetchVendorPayoutDetail}
              vendorDetail={vendorDetail as any}
              vendorPayoutDetail={(vendorPayoutDetail ?? {}) as any}
            />
          </div>
        </div>
      </div>
      {showFilter && (
        <VendorTransactionFilter
          hideAdvancedFilter={hideAdvancedFilter}
          cancelAdvancedFilter={cancelAdvancedFilter}
          bankOptions={bankOptions}
          selectedBank={selectedBank}
          onChangeSelectHandler={onChangeSelectHandler}
          setChequeNo={setChequeNo}
          chequeNo={chequeNo}
          onInputChange={onInputChange}
          onStartDateChangeHandler={onStartDateChangeHandler}
          onEndDateChangeHandler={onEndDateChangeHandler}
          startDate={startDate}
          endDate={endDate}
          onApplyHandler={onApplyHandler}
          ErrorMessage={ErrorMessage}
          resetEndDate={resetEndDate}
          resetStartDate={resetStartDate}
          showFilter={showFilter}
        />
      )}

      {/* {show && vendorPayoutDetail?.data?.remainingAmountToPay !== 0 ? (
        <div className="vendorPayout-wrapper">
          <div>
            <Icons.blackAlertCircle />{' '}
            <span className="ms-1 body-regular">
              {' '}
              The vendor has requested a payment of{' '}
              <span className="ms-1 body-regular-semibold">
                {' '}
                Rs. {formatAmountToStandardFormat(vendorPayoutDetail?.data?.remainingAmountToPay) ?? 0}{' '}
              </span>
            </span>
          </div>
          <div onClick={showPayout} style={{ cursor: 'pointer' }}>
            {' '}
            <Icons.crossButton />{' '}
          </div>
        </div>
      ) : (
        <div></div>
      )} */}

      <div className="vendorSummary-container">
        <div className="vendorSummary-sidebar">
          <VendorSummary vendorDetail={vendorDetail} refetchVendor={refetchVendor} />
        </div>
        <>
          <div className="vendorSummary-full">
            <div className="list-wrapper">
              <div className="vendor-transaction">
                <div>
                  <div className="h4-semibold vendor-transaction-title">Transaction report</div>
                </div>
                <div className="transaction-report">
                  <div className="total-transaction">
                    <div>
                      <div className="body-regular-semibold total-transaction-amount ">
                        Rs.{formatAmountToStandardFormat(transactionDetail?.totalTransactionAmount) ?? 0}
                      </div>
                      <div className="h5-regular total-transaction-text">Transaction for period</div>
                      <div className="h5-semibold vendor-transaction-text">
                        {`${format(selectedStartDate ?? subDays(new Date(), 30), 'MMM dd, yyyy')} - ${format(
                          selectedEndDate ?? new Date(),
                          'MMM dd, yyyy'
                        )}`}{' '}
                      </div>
                    </div>

                    <div className="total-transaction-icon">
                      <Icons.TrendupIcon />
                    </div>
                  </div>
                  <div className="total-transaction">
                    <div>
                      <div className="body-regular-semibold total-transaction-amount">
                        Rs.{formatAmountToStandardFormat(transactionDetail?.balanceAmount) ?? 0}
                      </div>
                      <div className="h5-regular total-transaction-text">Balance for period</div>
                      <div className="h5-semibold vendor-transaction-text">
                        {`${format(selectedStartDate ?? subDays(new Date(), 30), 'MMM dd, yyyy')} - ${format(
                          selectedEndDate ?? new Date(),
                          'MMM dd, yyyy'
                        )}`}{' '}
                      </div>
                    </div>

                    <div className="total-transaction-icon">
                      <Icons.ClockIcon />
                    </div>
                  </div>
                  <div className="total-transaction">
                    <div>
                      <div className="body-regular-semibold total-transaction-amount">
                        Rs.{formatAmountToStandardFormat(transactionDetail?.totalTransactionAmountTillNow) ?? 0}
                      </div>
                      <div className="h5-regular total-transaction-text">Total Transaction</div>
                    </div>
                    <div className="total-transaction-icon">
                      <Icons.TrendupIcon />
                    </div>
                  </div>

                  <div className="total-transaction">
                    <div>
                      <div className="body-regular-semibold total-transaction-amount">
                        Rs.{formatAmountToStandardFormat(transactionDetail?.totalBalanceAmountTillNow)}
                      </div>
                      <div className="h5-regular total-transaction-text">Balance to pay</div>
                    </div>
                    <div className="total-transaction-icon">
                      <Icons.ClockIcon />
                    </div>
                  </div>
                </div>
              </div>
              {transactionDetail?.vendorTransactions && transactionDetail?.vendorTransactions?.length <= 0 ? (
                <div className="vendor-list-table">
                  <div className="empty-message body-large-regular mt-5 empty-data">
                    <Icons.noTransactionIcon />
                    No Transaction to display.
                  </div>
                </div>
              ) : (
                <div className="table-responsive vendor-list-table" style={{ overflowY: 'auto' }}>
                  <table className="table">
                    <thead className="thead department-thead" style={{ zIndex: 2 }}>
                      <tr className="department-header">
                        <th className="h5-semibold-uppercase">DATE</th>
                        <th className="h5-semibold-uppercase">BANK</th>
                        <th className="h5-semibold-uppercase">CHEQUE NO</th>
                        <th className="h5-semibold-uppercase">REMARKS</th>
                        <th className="h5-semibold-uppercase">TRANSACTION AMOUNT</th>
                        <th className="h5-semibold-uppercase">PAID</th>
                        <th className="h5-semibold-uppercase">BALANCE </th>
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      {transactionDetail?.vendorTransactions?.map((transaction: any, index: any) => (
                        <tr key={index}>
                          <td className="align-middle emp-id body-small-regular">
                            {convertDateFormat(transaction?.date)}
                          </td>
                          <td className="align-middle emp-id body-small-regular">
                            {Array.isArray(transaction?.bankName) &&
                            !transaction.bankName.every((bank: string) => bank === '')
                              ? transaction.bankName.map((bank: any, index: any) => <div key={index}>{bank}</div>)
                              : '-'}
                          </td>
                          <td className="align-middle emp-id body-small-regular">
                            {Array.isArray(transaction?.chequeNo) &&
                            !transaction.chequeNo.every((chequeNo: string) => chequeNo === '')
                              ? transaction.chequeNo.map((chequeNo: any, index: any) => (
                                  <div key={index}>{chequeNo}</div>
                                ))
                              : '-'}
                          </td>
                          <td className="align-middle emp-id body-small-regular">
                            {Array.isArray(transaction?.remarks) &&
                            !transaction.remarks.every((remark: string) => remark === '')
                              ? transaction.remarks.map((remark: any, index: any) => <div key={index}>{remark}</div>)
                              : '-'}
                          </td>
                          <td className="align-middle emp-id body-small-regular">
                            {`Rs.${formatAmountToStandardFormat(transaction?.transactionAmount)}` || '-'}
                          </td>
                          <td className="align-middle emp-id body-small-regular">
                            {`Rs.${formatAmountToStandardFormat(transaction?.paidAmount)}` || '-'}
                          </td>
                          <td className="align-middle emp-id body-small-regular">
                            {`Rs.${formatAmountToStandardFormat(transaction?.balance)}` || '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  ) : null;
};
export default VendorView;
