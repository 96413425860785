import { useViewListContext } from '@attendance-web-app/global-state/context/ViewContext';
import getDesignationServices from '@attendance-web-app/services/designation.service';
import { IDepartmentList } from '@attendance-web-app/types/department/type';
import { IDesignation } from '@attendance-web-app/types/designation/type';
import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import DepartmentSummary from '../_DepartmentSummary/DepartmentSummary';
import DesignationListHeader from '../_DesignationList/DesignationListHeader';
import DesignationListTable from '../_DesignationList/DesignationListTable';
import ViewEmployeeListHeader from '../_ViewEmployeeList/ViewEmployeeListHeader';
import ViewEmployeeListTable from '../_ViewEmployeeList/ViewEmployeeListTable';
interface IListProps {
  departmentDetails: IDepartmentList;
}

const List = ({ departmentDetails }: IListProps) => {
  const { viewListState } = useViewListContext();
  const [tabIndex, setTabIndex] = useState(viewListState.index);
  const initialDesignation = {
    id: 0,
    name: '',
    employees: [],
    employeeCount: 0,
    isArchived: false
  };

  const [isDesignationAdded, setIsDesignationAdded] = useState(false);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [filteredDesignationData, setFilteredDesignationData] = useState<IDesignation[]>([initialDesignation]);

  const [designationList, setDesignationList] = useState<IDesignation[]>([initialDesignation]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const fetchDesignationData = async (search: string = '') => {
    try {
      setLoading(true);
      const designationData = await getDesignationServices.getDesignationList({
        department: departmentDetails.departments[0].departmentIdNo
      });

      if (Array.isArray(designationData.data)) {
        if (search.length !== 0) {
          const filteredList: IDesignation[] = designationList.filter(designation =>
            designation.name.toLowerCase().includes(search.toLowerCase())
          );
          setFilteredDesignationData(filteredList);
        }
        setDesignationList([...designationData.data]);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)} className="p-0">
      <TabList className="modal-tablist mx-4">
        <Tab className="body-regular-semibold no-bullets ">Designation list</Tab>
        <Tab className="body-regular-semibold no-bullets ">Employee List</Tab>
      </TabList>
      <TabPanel>
        <div className="row tab-wrapper mt-2">
          <div className="col-4 col-md-4 col-xl-3 col-lg-3  col-xxl-2 mt-2 p-0">
            <DepartmentSummary departmentDetails={departmentDetails} />
          </div>
          <div className="col-8  col-md-8 col-xl-9 col-lg-9  col-xxl-10 p-0 mt-2">
            <DesignationListHeader
              departmentDetails={departmentDetails}
              fetchDesignationData={fetchDesignationData}
              setIsDesignationAdded={setIsDesignationAdded}
              setSearchKeyword={setSearchKeyword}
            />
            <DesignationListTable
              designationList={designationList}
              filteredDesignationData={filteredDesignationData}
              fetchDesignationData={fetchDesignationData}
              searchKeyword={searchKeyword}
              isLoading={isLoading}
              departmentIdNo={departmentDetails.departments[0].departmentIdNo}
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="row tab-wrapper mt-2">
          <div className="col-4 col-md-4 col-xl-3 col-lg-3  col-xxl-2  p-0 mt-2">
            <DepartmentSummary departmentDetails={departmentDetails} />
          </div>
          <div className="col-8  col-md-8 col-xl-9 col-lg-9  col-xxl-10 p-0 mt-2">
            <ViewEmployeeListHeader
              departmentDetails={departmentDetails}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
            <ViewEmployeeListTable departmentDetails={departmentDetails} searchKeyword={searchKeyword} />
          </div>
        </div>
      </TabPanel>
    </Tabs>
  );
};

export default List;
