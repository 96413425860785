import { Icons } from '@attendance-web-app/assets/Icons/Icons';
import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import LoadingScreen from '@attendance-web-app/components/Common/LoadingScreen';
import CustomPopover from '@attendance-web-app/components/Common/PopOver';
import ShiftBatch from '@attendance-web-app/components/Common/ShiftBatch';
import { useEmployeeContext } from '@attendance-web-app/global-state/context/EmployeeContext';
import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { DisableEmployeeModal } from './DisableEmployeeModal';
import EditEmployeeModal from './EditEmployeeModal';

interface IEmployeeListTable {
  isLoading: boolean;
  onEmployeeUpdate: () => void;
}

const EmployeeListTable = ({ isLoading }: IEmployeeListTable) => {
  const [modalShow, setModalShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [modalEditShow, setModalEditShow] = React.useState(false);
  const [showHODDropdown, setShowHODDropdown] = useState<boolean[]>([]);
  const [showSupervisorDropdown, setShowSupervisorDropdown] = useState<boolean[]>([]);

  const { employeeState } = useEmployeeContext();

  const handleOpen = (id: number) => {
    setSelectedId(id);
    setIsMenuOpen(!isMenuOpen);
    setModalShow(false);
    setModalEditShow(false);
  };

  const handleDropdownEnter = (index: number, isHod: boolean) => {
    if (isHod) {
      setShowHODDropdown(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
    } else {
      setShowSupervisorDropdown(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
    }
  };

  const handleDropdownLeave = (index: number, isHod: boolean) => {
    if (isHod) {
      setShowHODDropdown(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    } else {
      setShowSupervisorDropdown(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  return (
    <LoadingScreen isLoading={isLoading}>
      <div className="col-12 list-wrapper">
        <div className="attendance-table ">
          <table className="table table-responsive">
            <thead className="thead body-regular-semibold attendance-thead">
              <tr className="table-header">
                <th className="h5-semibold-uppercase">Id</th>
                <th className="h5-semibold-uppercase">Employee Details</th>
                <th className="h5-semibold-uppercase">Shift</th>
                <th className="h5-semibold-uppercase">Department</th>
                <th className="h5-semibold-uppercase px-6">HOD</th>
                <th className="h5-semibold-uppercase">Supervisor</th>

                <th className="h5-semibold-uppercase">Status </th>
              </tr>
            </thead>

            <tbody className="tbody">
              {employeeState?.data?.map((employees, index) => (
                <tr key={employees.id}>
                  <td className="align-middle emp-id body-small-regular">
                    {employees.employeeIdNo ? employees.employeeIdNo : '-'}
                  </td>
                  <td className="empDetail">
                    <div className="d-flex">
                      <div className="empImage py-2 pe-3">
                        {employees?.profilePhoto ? (
                          <img
                            src={employees.profilePhoto}
                            alt={employees.name}
                            width="35px"
                            height="35px"
                            className="round-image"
                          />
                        ) : (
                          <AvatarName name={employees.email ?? ''} />
                        )}
                      </div>
                      <div className="dash-detail body-small-regular mt-2">
                        <span className="employee-name">{employees.name} </span>
                        <div className="bottomText">
                          <span className="email">{employees.email}</span>

                          <div className="position">
                            {/* {
                                    <Icon
                                      icon="material-symbols:circle"
                                      width="4px"
                                      height="4px"
                                      color="grey"
                                      style={{ marginRight: '5px' }}
                                    />
                                  } */}
                            <span>{employees.designation?.name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="body-small-regular align-middle ">
                    <ShiftBatch shift={employees?.shift ?? 'Day'} />
                  </td>
                  <td className="align-middle">
                    {employees.department ? (
                      <span className="body-small-regular">{employees.department?.name}</span>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="align-middle">
                    {employees.department == null ? (
                      <div className="body-small-regular">Not Assigned</div>
                    ) : employees.department?.hod === null ? (
                      <div className="body-small-regular">Not Assigned</div>
                    ) : (
                      <Dropdown
                        onMouseEnter={() => handleDropdownEnter(index, true)}
                        onMouseLeave={() => handleDropdownLeave(index, true)}
                        show={showHODDropdown[index]}
                        className="employee-dropdown"
                        drop={'start'}
                      >
                        <Dropdown.Toggle className="main-style name" id={`dropdown-basic-hod-${index}`} as="div">
                          <span className="hover-title-employee">{employees?.department?.hod?.employee?.name}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="items" onMouseEnter={() => handleDropdownEnter(index, true)}>
                          <Dropdown.Item>
                            <CustomPopover
                              photo={employees.department.hod?.employee.profilePhoto}
                              name={employees.department.hod?.employee.name}
                              id={employees.department.hod?.employee.employeeIdNo}
                              department={employees.department.hod?.employee.department.name}
                              designation={employees.department.hod?.employee.designation.name}
                              phoneNumber={employees.department.hod?.employee.mobile}
                              email={employees.department.hod?.employee.email}
                            />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>
                  <td className="align-middle">
                    {employees.supervisor === null ? (
                      <div className="body-small-regular">Not Assigned</div>
                    ) : (
                      <Dropdown
                        onMouseEnter={() => handleDropdownEnter(index, false)}
                        onMouseLeave={() => handleDropdownLeave(index, false)}
                        show={showSupervisorDropdown[index]}
                        className="employee-dropdown"
                        drop={'start'}
                      >
                        <Dropdown.Toggle className="main-style name" id={`dropdown-basic-supervisor-${index}`} as="div">
                          {employees.supervisor === null ? (
                            <div className="body-small-regular">Not Assigned</div>
                          ) : (
                            <div className="hover-title-employee">{employees.supervisor.name}</div>
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="items" onMouseEnter={() => handleDropdownEnter(index, false)}>
                          <Dropdown.Item>
                            <CustomPopover
                              photo={employees.supervisor.profilePhoto}
                              name={employees.supervisor.name}
                              id={employees.supervisor.employeeIdNo}
                              department={employees.supervisor?.department?.name}
                              designation={employees.supervisor?.designation?.name}
                              phoneNumber={employees.supervisor?.mobile}
                              email={employees.supervisor?.email}
                            />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>

                  <td className="body-small-regular align-middle status">
                    <div className="d-flex justify-content-between">
                      <div className="flex-start chip">
                        {employees.status === true ? (
                          <div className="chip-active-employee">
                            <div className="active-status"></div>
                            <span className="chip-text-employee"> Active </span>
                          </div>
                        ) : (
                          <div className="chip-disable-employee">
                            <div className="inactive-status"></div>
                            <span className="chip-disabled-text-employee"> Disabled </span>
                          </div>
                        )}
                      </div>

                      <div className="employee-dropdown-option">
                        <DropdownButton
                          drop="up"
                          style={{ marginRight: '45px' }}
                          title={<Icons.OptionIcon height={20} width={20} onClick={() => handleOpen(employees?.id)} />}
                          className="option-icon-employee"
                        >
                          {selectedId === employees.id && (
                            <>
                              <Dropdown.Item
                                onClick={() => setModalEditShow(true)}
                                className="edit-employee-options body-small-regular"
                                disabled={!employees.status}
                              >
                                {employees && employees.status ? (
                                  <Icons.EditEmployeeIcon className="employee-icon" />
                                ) : (
                                  <Icons.DiasbledEditEmployeeIcon className="employee-icon" />
                                )}
                                Edit employee
                              </Dropdown.Item>
                              <EditEmployeeModal
                                id={employees.id}
                                employeeListState={employeeState.data ?? []}
                                show={modalEditShow}
                                onHide={() => setModalEditShow(false)}
                                setIsMenuOpen={setIsMenuOpen}
                                employees={employees}
                              />
                              <Dropdown.Item
                                className={`body-small-regular ${
                                  employees.status === true
                                } ? 'disable-user' : 'enable-user'`}
                                onClick={() => setModalShow(true)}
                              >
                                {employees.status === true ? (
                                  <>
                                    <Icons.DisableUserIcon className="employee-icon" />
                                    <span>Disable User</span>
                                  </>
                                ) : (
                                  <>
                                    <Icons.EnableUserIcon className="employee-icon" />
                                    <span>Enable User</span>
                                  </>
                                )}
                              </Dropdown.Item>
                              <DisableEmployeeModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                setModalShow={setModalShow}
                                id={employees.employeeIdNo}
                                employeeListState={employeeState.data ?? []}
                                setIsMenuOpen={setIsMenuOpen}
                                customSize={'md'}
                              />
                            </>
                          )}
                        </DropdownButton>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {employeeState.data && employeeState?.data?.length <= 0 && (
            <div className="empty-message body-large-regular">No data to display</div>
          )}
        </div>
      </div>
    </LoadingScreen>
  );
};
export default EmployeeListTable;
