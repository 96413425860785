import Alert from '@attendance-web-app/components/Common/Alert';
import AvatarName from '@attendance-web-app/components/Common/AvatarName';
import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import { saveEmployeeSuccess } from '@attendance-web-app/global-state/actions/employeeAction';
import { useEmployeeContext } from '@attendance-web-app/global-state/context/EmployeeContext';
import { getEmployeeListApi } from '@attendance-web-app/pages/employeeList/hooks/useEmployeeApiHook';
import departmentServices from '@attendance-web-app/services/department.services';
import {
  default as employeeServices,
  default as getAllEmployeeServices
} from '@attendance-web-app/services/employee.services';
import { IDepartmentDropdownOptions } from '@attendance-web-app/types/department/type';
import { IDesignationFormData } from '@attendance-web-app/types/designation/type';
import { IEmployee, IemployeeSupervisorDropdownOptions } from '@attendance-web-app/types/employee/type';
import { shiftArr } from '@attendance-web-app/utils';
import { handleErrorMessage } from '@attendance-web-app/utils/errorMessage';
import useCustomListApi from '@attendance-web-app/utils/hooks/useCustomListApi';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';
import roledata from '../../../__mocks__/role.json';

interface IEditEmployeeModalProps {
  id: number;
  employeeListState: IEmployee[];
  show: boolean;
  onHide: () => void;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employees: IEmployee;
}
interface inviteEmployeeFormFields {
  fullName: string;
  email: string;
  employeeId: string;
  department: IDepartmentDropdownOptions;
  role: any;
  employeeGrade: string;
  mobileNumber: number;
  designation: IDesignationFormData | null | undefined;
  supervisorId: IemployeeSupervisorDropdownOptions | null | undefined;
  subDepartment: IDesignationFormData;
  shift?: 'day' | 'evening';
}
const EditEmployeeModal = ({ show, onHide, setIsMenuOpen, employees }: IEditEmployeeModalProps) => {
  const [supervisor, setSupervisor] = useState<any>({
    value: employees?.supervisor?.id,
    label: employees?.supervisor?.email
  });
  const [designation, setDesignation] = useState<any>({
    value: Number(employees?.designation?.id),
    label: employees?.designation?.name,
    grade: employees?.designation?.grade
  });
  const [subDepartment, setSubDepartment] = useState<any>({
    value: Number(employees?.subDepartment?.id),
    label: employees?.subDepartment?.name
  });
  const [department, setDepartment] = useState<IDepartmentDropdownOptions>({
    value: String(employees?.department?.departmentIdNo),
    label: String(employees?.department?.name),
    id: employees?.department?.id
  });
  const [role, setRole] = useState({ value: employees.role.id, label: employees.role.role });
  const invitedemployeeId = JSON.parse(localStorage.getItem('admin_user') as string).id;
  const [deptOptions, setDeptOtions] = useState<any>([{ value: '', label: '' }]);
  const [typeSuperVisor, setTypedSuperVisor] = useState(supervisor?.label);
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const [desginationOptions, setdesginationOptions] = useState<any>([{ value: 0, label: '' }]);
  const [superVisorOptions, setsuperVisorOptions] = useState<any>([{ value: '', label: '' }]);
  const [supervisorInput, setSuperVisorInput] = useState(supervisor?.label);

  const [subDepartmentOptions, setSubDepartmentOptions] = useState<any>([{ value: 0, label: '' }]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty },
    setError,
    setValue,
    getValues
  } = useForm<inviteEmployeeFormFields>({
    defaultValues: {
      fullName: employees.name,
      email: employees.email,
      employeeId: employees.employeeIdNo || '',
      department: department,
      role: role,
      employeeGrade: employees.designation.grade,
      mobileNumber: employees.mobile || 0,
      designation: designation || { label: '', value: 0, grade: '' },
      supervisorId: supervisor,
      subDepartment: subDepartment,
      shift: employees.shift
    }
  });
  const { employeeState, employeeDispatch } = useEmployeeContext();
  const { paramsValue } = employeeState;
  const { refetchQuery: refetchEmployeeList } = useCustomListApi({
    queryKey: ['employeeList'],
    getApiFn: getEmployeeListApi,
    enabled: false,
    params: {}
  });

  useEffect(() => {
    if (getValues('department.id')) {
      getSuperVisorOptions();
      getSubDepartmentOptions();
    }
    if (getValues('subDepartment.label')) {
      getDesignationOptions();
    }
  }, []);

  useEffect(() => {
    getdepartmentOptions();
    const subscription = watch((_, { name }) => {
      if (name === 'department') {
        getSuperVisorOptions();
        //getDesignationOptions();
        getSubDepartmentOptions();
      }
      if (name === 'subDepartment') {
        getDesignationOptions();
        getSuperVisorOptions();
      }
    });
    return () => subscription.unsubscribe();
  }, [typeSuperVisor, watch]);

  const getSuperVisorOptions = async () => {
    try {
      const employeeListArgs = {
        name: typeSuperVisor,
        department: String(getValues('department.id')),
        limit: 2,
        offset: 0
      };
      const superVisor = await employeeServices.employeeList(employeeListArgs);
      const superViosrData = superVisor.data.employee.map(employee => {
        return {
          label: employee.name,
          email: employee.email,
          image: employee.profilePhoto,
          id: employee.id,
          employeeIdNo: employee.employeeIdNo
        };
      });
      setsuperVisorOptions(superViosrData);
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  const handleCancel = () => {
    onHide();
    setIsMenuOpen(false);
  };

  const getdepartmentOptions = async () => {
    try {
      const getdepartmentListArgs = { name: '' };
      const allDepartment = await departmentServices.getdepartmentList(getdepartmentListArgs);
      const allDepartmentData = allDepartment?.data?.departments?.map((department: any) => ({
        value: department?.departmentIdNo,
        label: department?.name,
        id: department?.id
      }));
      setDeptOtions(allDepartmentData);
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  const getSubDepartmentOptions = async () => {
    try {
      if (getValues('department.value')) {
        setValue('subDepartment.value', undefined);
        const selectedSubDepartment = await axios.get('/sub-department', {
          params: { departmentId: getValues('department.id'), limit: 100, offset: 0 }
        });
        setSubDepartmentOptions(
          selectedSubDepartment.data?.items?.map((subDepartment: any) => ({
            value: subDepartment.id,
            label: subDepartment.name
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDesignationOptions = async () => {
    try {
      const selectedDesignation = await axios.get('/designation', {
        params: { subDepartment: getValues('subDepartment.label') }
      });
      setdesginationOptions(
        selectedDesignation.data.map((desingation: any) => ({
          value: desingation.id,
          label: desingation.name,
          grade: desingation.grade
        }))
      );
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  const onSubmit: SubmitHandler<any> = async data => {
    try {
      const updateEmployeeBody = {
        name: data.fullName,
        email: data.email,
        mobile: data.mobileNumber,
        designation: data.designation.value,
        department: Number(data.department.id),
        role: data.role.value || data.role,
        employeeIdNo: data.employeeId,
        employeeId: invitedemployeeId,
        grade: data.employeeGrade,
        supervisorId: data.supervisorId?.id,
        shift: data.shift
      };

      await getAllEmployeeServices.updateEmployee(employees?.employeeIdNo || '', updateEmployeeBody);
      Alert('success', 'Employee updated successfully.');
      onHide();
      setIsMenuOpen(false);
      refetchEmployeeList(paramsValue ?? {})
        .then((response: any) => {
          employeeDispatch(
            saveEmployeeSuccess({
              data: response.employee,
              totalCount: response.totalCount,
              totalPages: response.totalPages
            })
          );
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err: any) {
      err.response.data.message.map((message: any) => {
        if (message.includes('Email')) {
          setError('email', { message: message });
        }
        if (message.includes('Mobile')) {
          setError('mobileNumber', { message: message });
        }
        if (message.includes('EmployeeIdNo')) {
          setError('employeeId', { message: message });
        }
        if (message.includes('designation')) {
          setError('designation', { message: message });
        }
      });
    }
  };
  return (
    <Modal
      onExit={handleCancel}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      fullscreen={true}
      className="edit-employee-modal"
    >
      <Modal.Header closeButton onClick={handleCancel}>
        <Modal.Title id="contained-modal-title-vcenter" className="h4-semibold">
          Edit Employee
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex pb-4 mb-4 ms-3 border-bottom">
          <div className="flex-shrink-0">
            {employees.profilePhoto ? (
              <Image alt={employees.email} src={employees.profilePhoto} className="update-image round-image" rounded />
            ) : (
              <AvatarName name={employees.name ?? ''} otherClass="mt-1" />
            )}
          </div>
          <div className="flex-shrink-1 ms-3">
            <div className="header-name">{employees?.name}</div>
            <div className="header-designation pt-1">{employees?.designation?.name}</div>
          </div>
        </div>
        <form action="" onSubmit={handleSubmit(onSubmit)} id="edit-form">
          <div className="row">
            <Form.Floating className="mb-3 col-6">
              <Form.Control
                type="text"
                id="fullName"
                placeholder=" "
                {...register('fullName', { required: true })}
                isInvalid={!!errors.fullName}
              />
              <label
                htmlFor="floatingInputCustom"
                className={`ms-2 edit-employee ${errors.fullName ? 'text-danger' : ''}`}
              >
                Full name
              </label>
              {errors.fullName && <ErrorLabel message={'Full name is required'} />}
            </Form.Floating>
            <Form.Floating className="col-6">
              <Form.Control
                type="text"
                id="employeeId"
                placeholder=" "
                {...register('employeeId', { required: true })}
                isInvalid={!!errors.employeeId}
                disabled={true}
              />
              <label
                htmlFor="floatingInputCustom"
                className={`ms-2 edit-employee ${errors.employeeId ? 'text-danger' : ''} `}
              >
                Employee ID
              </label>
              {errors.employeeId && <ErrorLabel message={errors.employeeId.message || 'Employee ID is required'} />}
            </Form.Floating>
          </div>

          <div className="row">
            <div className="form-floating pb-2 margin">
              <Controller
                control={control}
                render={({ field: { onChange, name } }) => {
                  const handleSelectChange = (selectedOption: IDepartmentDropdownOptions | null) => {
                    if (selectedOption) {
                      onChange(selectedOption);
                      setDepartment(selectedOption);
                      setSupervisor(null);
                      setValue('supervisorId', undefined);
                      setTypedSuperVisor('');
                      setSuperVisorInput('');
                      setValue('designation', undefined);
                      setDesignation(null);
                    }
                  };

                  return (
                    <Select
                      value={department}
                      name={name}
                      options={deptOptions}
                      onChange={handleSelectChange}
                      className="select-input"
                      placeholder="-Select Department-"
                    />
                  );
                }}
                name="department"
                rules={{
                  required: true
                }}
              />
              <label className="select-label" htmlFor="selectdepartment">
                <span className="select-text edit-employee-select">Department</span>
              </label>
              {errors.department && <ErrorLabel message={errors.department.message || 'Department is required'} />}
            </div>
          </div>

          <div className="row">
            <div className="form-floating col-6 margin">
              <Controller
                control={control}
                render={({ field: { onChange, name } }) => {
                  const handleSelectChange = (selectedOption: any | null) => {
                    if (selectedOption) {
                      onChange(selectedOption);
                      setSubDepartment(selectedOption);
                      setSupervisor(null);
                      setValue('supervisorId', undefined);
                      setTypedSuperVisor('');
                      setSuperVisorInput('');
                    }
                  };

                  return (
                    <Select
                      value={subDepartment}
                      name={name}
                      options={getValues('department.value') ? subDepartmentOptions : undefined}
                      onChange={handleSelectChange}
                      className={`select-input ${errors.subDepartment ? 'has-error' : ''}`}
                      placeholder="-Select -"
                      noOptionsMessage={() => 'No Sub Department Found'}
                    />
                  );
                }}
                name="subDepartment"
                rules={{
                  required: true
                }}
              />
              <label className="select-label" htmlFor="selectdepartment">
                <span className={`select-text edit-employee-select ${errors.subDepartment ? 'text-danger' : ''}`}>
                  Select Sub Department
                </span>
              </label>
              {errors.subDepartment && (
                <ErrorLabel message={errors.subDepartment.message || 'Sub Department is required'} />
              )}
            </div>
            <div className="form-floating col-6 margin">
              <Controller
                control={control}
                render={({ field: { onChange, name } }) => {
                  const handleSelectChange = (selectedOption: any | null) => {
                    if (selectedOption) {
                      onChange(selectedOption);
                      setDesignation(selectedOption);
                      setSupervisor(null);
                      setValue('supervisorId', undefined);
                      setTypedSuperVisor('');
                      setSuperVisorInput('');
                      setValue('employeeGrade', selectedOption.grade);
                    }
                  };

                  return (
                    <Select
                      value={designation}
                      name={name}
                      options={getValues('department.value') ? desginationOptions : undefined}
                      onChange={handleSelectChange}
                      className={`select-input ${errors.designation ? 'has-error' : ''}`}
                      placeholder="-Select Designation-"
                      noOptionsMessage={() => 'No Designation Found'}
                    />
                  );
                }}
                name="designation"
                rules={{
                  required: true
                }}
              />
              <label className="select-label" htmlFor="selectdepartment">
                <span className={`select-text edit-employee-select ${errors.designation ? 'text-danger' : ''}`}>
                  Select Designation
                </span>
              </label>
              {errors.designation && <ErrorLabel message={errors.designation.message || 'Designation is required'} />}
            </div>
          </div>
          <div>
            <div className="row mt-3 mb-3 justify-content-between">
              {/* Role */}
              <div className="form-floating col-6 margin">
                <Controller
                  control={control}
                  render={({ field: { onChange, name } }) => {
                    const handleSelectChange = (selectedOption: any | null) => {
                      if (selectedOption) {
                        onChange(selectedOption.value);
                        setRole(selectedOption);
                      }
                    };

                    return (
                      <Select
                        value={role}
                        name={name}
                        options={roledata.role}
                        onChange={handleSelectChange}
                        className={`select-input ${errors.role ? 'has-error' : ''}`}
                        placeholder="-Select Role-"
                      />
                    );
                  }}
                  name="role"
                  rules={{
                    required: true
                  }}
                />
                <label className="select-label" htmlFor="selectdepartment">
                  <span className="select-text edit-employee-select">Select Role </span>
                </label>
              </div>

              {/* Shift */}
              <div className="form-floating col-6 margin">
                <Controller
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    const selectedShift = shiftArr.find((c: any) => c.value === value);
                    const handleSelectChange = (selectedOption: any | null) => {
                      if (selectedOption) {
                        onChange(selectedOption.value);
                      }
                    };

                    return (
                      <Select
                        value={selectedShift ?? shiftArr[0]}
                        defaultValue={shiftArr[0]}
                        name={name}
                        options={shiftArr}
                        onChange={handleSelectChange}
                        className={`select-input ${errors.shift ? 'has-error' : ''}`}
                        placeholder="-Select Shift-"
                      />
                    );
                  }}
                  name="shift"
                />
                <label
                  className={`select-label invite-employee-select ${errors.shift ? 'text-danger' : ''}`}
                  id="designation"
                  htmlFor="selectShift"
                >
                  <span className="select-text">
                    Select Shift <span className="asteriskEmployee">*</span>
                  </span>
                </label>
                {errors.shift && <ErrorLabel message={errors.shift?.message || 'Required'} />}
              </div>
            </div>
          </div>

          {/* Employee Grade And Mobile Number */}
          <div className="row mt-3">
            <Form.Floating className=" col-6 mt-2">
              <Form.Control
                id="employeeGrade"
                type="text"
                placeholder=" "
                {...register('employeeGrade', { required: true })}
                isInvalid={!!errors.employeeGrade}
              />
              <label
                htmlFor="floatingInputCustom"
                className={`ms-2 edit-employee-grade ${errors.employeeGrade ? 'text-danger' : ''}`}
              >
                Employee grade
              </label>
              {errors.employeeGrade && (
                <ErrorLabel message={errors.employeeGrade.message || 'Employee grade is required'} />
              )}
            </Form.Floating>
            <Form.Floating className=" col-6 mt-2">
              <Form.Control
                id="mobileNumber"
                type="tel"
                placeholder=" "
                maxLength={10}
                {...register('mobileNumber', {
                  required: true,

                  validate: {
                    isNumber: value => !isNaN(value) || 'Mobile number must be a number',
                    isTenDigits: value => String(value).length === 10 || 'Phone number must be 10 digits long'
                  }
                })}
                isInvalid={!!errors.mobileNumber}
              />
              <label
                htmlFor="floatingInputCustom"
                className={`ms-2 edit-employee-grade ${errors.mobileNumber ? 'text-danger' : ''}`}
              >
                Mobile Number
              </label>
              {errors.mobileNumber && (
                <ErrorLabel message={errors.mobileNumber.message || 'Phone number is required'} />
              )}
            </Form.Floating>
          </div>
          {/* Supervisor */}
          <div className="row mt-3">
            <div className="form-floating  margin">
              <Controller
                control={control}
                render={({ field: { onChange, name } }) => {
                  const handleSelectChange = (selectedOption: any | null) => {
                    if (selectedOption === null) {
                      onChange(selectedOption);
                      setSupervisor(selectedOption);
                      setValue(name, selectedOption);
                      setOptionsOpen(true);
                      setTypedSuperVisor('');
                      setSuperVisorInput('');
                    }
                    if (selectedOption) {
                      onChange(selectedOption);
                      setSupervisor(selectedOption);
                      setValue(name, selectedOption);
                      setOptionsOpen(false);
                      setTypedSuperVisor(selectedOption.label);
                    }
                  };

                  const handleInputSupervisor = (valueinput: any, action: any) => {
                    if (action.action === 'input-change') {
                      setTypedSuperVisor(valueinput);
                      setSuperVisorInput(valueinput);
                    }
                  };

                  const Option = (props: any) => {
                    const { label, email, image, id, employeeIdNo } = props.data;
                    if (getValues('department.id')) {
                      return (
                        <div
                          className="d-flex pb-2 pt-2 mb-4 ms-3 border-bottom"
                          onClick={() => {
                            handleSelectChange({
                              label: email,
                              id: id,
                              image: image,
                              name: label
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="flex-shrink-0">
                            <img
                              src={image}
                              alt={label}
                              style={{
                                width: '50px',
                                height: '50px',
                                marginRight: '10px'
                              }}
                            />
                          </div>
                          <div className="flex-shrink-1 ms-3 mt-1">
                            <div>{label}</div>
                            <div style={{ fontSize: '12px', color: '#999' }}>{email}</div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="d-flex pb-3 pt-3  ms-3 d-flex justify-content-center">
                          <span>Please select a Department</span>
                        </div>
                      );
                    }
                  };

                  return (
                    <Select
                      className="select-input"
                      name={name}
                      options={superVisorOptions}
                      placeholder="-Select Supervisor-"
                      isSearchable
                      value={supervisor?.value === undefined ? null : supervisor}
                      components={{ Option }}
                      onChange={handleSelectChange}
                      onInputChange={handleInputSupervisor}
                      menuIsOpen={isOptionsOpen}
                      inputValue={typeSuperVisor}
                      onKeyDown={() => setOptionsOpen(true)}
                      isClearable
                      onFocus={() => {
                        setOptionsOpen(true);
                      }}
                      onBlur={() => {
                        setOptionsOpen(false);
                      }}
                      noOptionsMessage={() => 'No Supervisor Found'}
                    />
                  );
                }}
                name="supervisorId"
              />
              <label className="select-label" htmlFor="selectdepartment">
                <span className="select-text edit-employee-select">Select supervisor</span>
              </label>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onHide} className="btn-tertiary" onClickCapture={handleCancel}>
          Cancel
        </button>
        <button className="btn" form="edit-form" disabled={isDirty ? false : true}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditEmployeeModal;
